import { isBefore, isWithinInterval } from "date-fns";
import Link from "next/link";
import { useTranslation } from "react-i18next";

import { useAuthenticatedUserClassrooms } from "@sol/classrooms";
import { interpolate, Route } from "@sol/routing";
import { ArrowRight } from "@sol/uikit/core/icons";
import Button from "@sol/uikit/general/Button";

import { ClassroomsList } from "./ClassroomsList";
import { HomeCard, HomeCardDescription } from "./HomeCard";

export function ManagerHome() {
    const { classrooms = [], isLoading } = useAuthenticatedUserClassrooms();
    const [t] = useTranslation();

    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <HomeCard
                title={t("component.home.manager.classrooms.title")}
                className="col-span-2 lg:col-span-1 lg:row-span-2"
            >
                <div className="flex w-full flex-col">
                    <HomeCardDescription>{t("component.home.manager.classrooms.description")}</HomeCardDescription>
                    <ClassroomsList
                        isLoading={isLoading}
                        className="!h-auto max-h-[370px] w-full overflow-auto sm:max-h-[325px]"
                        classrooms={classrooms.filter(c => {
                            const start = new Date(c.startDate);
                            const end = new Date(c.endDate);
                            // DEBT: data consistency should be ensured by the API
                            // here sometimes we encounter endDate before startDate
                            // this code mitigate the inconsistency
                            const interval = !isBefore(end, start)
                                ? {
                                      start,
                                      end,
                                  }
                                : {
                                      start: end,
                                      end: start,
                                  };

                            return isWithinInterval(new Date(), interval);
                        })}
                    />
                </div>
            </HomeCard>

            <HomeCard
                title={t("component.home.manager.follow-ups.title")}
                className="col-span-2 lg:col-span-1 lg:row-span-1"
                image={<img src="/static/home/growth.svg" alt="" />}
                action={
                    <Link href={interpolate(Route.FOLLOW_UPS_LIST, {})} passHref>
                        <Button
                            size="small"
                            iconPosition="end"
                            icon={<ArrowRight variant="antd" size={16} />}
                            title={t("component.home.manager.follow-ups.cta.title")}
                            aria-label={t("component.home.manager.follow-ups.cta.title")}
                        >
                            {t("component.home.manager.follow-ups.cta.label")}
                        </Button>
                    </Link>
                }
            >
                <HomeCardDescription label={t("component.home.manager.follow-ups.label")}>
                    {t("component.home.manager.follow-ups.description")}
                </HomeCardDescription>
            </HomeCard>
            <HomeCard
                title={t("component.home.manager.admin.title")}
                className="col-span-2 lg:col-span-1 lg:row-span-1"
                action={
                    <Link href={interpolate(Route.ADMIN, {})} passHref>
                        <Button
                            size="small"
                            iconPosition="end"
                            icon={<ArrowRight variant="antd" size={16} />}
                            title={t("component.home.manager.admin.cta.title")}
                            aria-label={t("component.home.manager.admin.cta.title")}
                        >
                            {t("component.home.manager.admin.cta.label")}
                        </Button>
                    </Link>
                }
            >
                <HomeCardDescription label={t("component.home.manager.admin.label")}>
                    {t("component.home.manager.admin.description")}
                </HomeCardDescription>
            </HomeCard>
        </div>
    );
}
