import { isBefore, isWithinInterval } from "date-fns";
import Link from "next/link";
import { useTranslation } from "react-i18next";

import { useAuthenticatedUserClassrooms } from "@sol/classrooms";
import { interpolate, Route } from "@sol/routing";
import { ArrowRight } from "@sol/uikit/core/icons";
import Button from "@sol/uikit/general/Button";

import { ClassroomsList } from "./ClassroomsList";
import { HomeCard, HomeCardDescription } from "./HomeCard";

export function TrainerHome() {
    const [t] = useTranslation();
    const { classrooms = [], isLoading } = useAuthenticatedUserClassrooms();

    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <HomeCard title={t("component.home.trainer.classrooms.title")} className="col-span-1 row-span-1">
                <ClassroomsList
                    classrooms={classrooms.filter(c => {
                        const start = new Date(c.startDate);
                        const end = new Date(c.endDate);
                        // DEBT: data consistency should be ensured by the API
                        // here sometimes we encounter endDate before startDate
                        // this code mitigate the inconsistency
                        const interval = !isBefore(end, start)
                            ? {
                                  start,
                                  end,
                              }
                            : {
                                  start: end,
                                  end: start,
                              };

                        return isWithinInterval(new Date(), interval);
                    })}
                    isLoading={isLoading}
                    className="!h-auto max-h-[370px] w-full overflow-auto sm:max-h-[245px]"
                />
            </HomeCard>
            <HomeCard
                title={t("component.home.trainer.trainer-workspace.title")}
                className="col-span-1 h-fit md:row-span-2 lg:h-full lg:!p-3"
            >
                <div className="flex min-h-0 w-full flex-col justify-between gap-6">
                    <HomeCardDescription label={t("component.home.trainer.trainer-workspace.label")} className="flex-1">
                        {t("component.home.trainer.trainer-workspace.description")}
                    </HomeCardDescription>

                    <div className="flex flex-col gap-3">
                        <HomeCard
                            className="border-[var(--ant-color-border-secondary)] bg-[var(--ant-color-fill-quaternary)]"
                            title={t("component.home.trainer.trainer-workspace.briefs.title")}
                            image={<img src="/static/home/media.svg" alt="" />}
                            action={
                                <Link href={interpolate(Route.TRAINER_WORKSPACE_BRIEFS, {})} passHref>
                                    <Button
                                        size="small"
                                        iconPosition="end"
                                        icon={<ArrowRight variant="antd" size={16} />}
                                        title={t("component.home.trainer.trainer-workspace.briefs.cta.title")}
                                        aria-label={t("component.home.trainer.trainer-workspace.briefs.cta.title")}
                                    >
                                        {t("component.home.trainer.trainer-workspace.briefs.cta.label")}
                                    </Button>
                                </Link>
                            }
                        >
                            <HomeCardDescription label={t("component.home.trainer.trainer-workspace.briefs.label")}>
                                {t("component.home.trainer.trainer-workspace.briefs.description")}
                            </HomeCardDescription>
                        </HomeCard>
                        <HomeCard
                            className="border-[var(--ant-color-border-secondary)] bg-[var(--ant-color-fill-quaternary)]"
                            title={t("component.home.trainer.trainer-workspace.professional-situations.title")}
                            image={<img src="/static/home/launch.svg" alt="" />}
                            action={
                                <Link href={interpolate(Route.TRAINER_WORKSPACE_PROFESSIONAL_SITUATION, {})} passHref>
                                    <Button
                                        size="small"
                                        iconPosition="end"
                                        icon={<ArrowRight variant="antd" size={16} />}
                                        title={t(
                                            "component.home.trainer.trainer-workspace.professional-situations.cta.title",
                                        )}
                                        aria-label={t(
                                            "component.home.trainer.trainer-workspace.professional-situations.cta.title",
                                        )}
                                    >
                                        {t(
                                            "component.home.trainer.trainer-workspace.professional-situations.cta.label",
                                        )}
                                    </Button>
                                </Link>
                            }
                        >
                            <HomeCardDescription
                                label={t("component.home.trainer.trainer-workspace.professional-situations.label")}
                            >
                                {t("component.home.trainer.trainer-workspace.professional-situations.description")}
                            </HomeCardDescription>
                        </HomeCard>
                    </div>
                </div>
            </HomeCard>
            <HomeCard
                title={t("component.home.trainer.follow-ups.title")}
                className="col-span-1 md:row-span-1"
                image={<img src="/static/home/growth.svg" alt="" />}
                action={
                    <Link href={interpolate(Route.FOLLOW_UPS_LIST, {})} passHref>
                        <Button
                            size="small"
                            icon={<ArrowRight variant="antd" size={16} />}
                            title={t("component.home.trainer.follow-ups.cta.title")}
                            aria-label={t("component.home.trainer.follow-ups.cta.title")}
                        >
                            {t("component.home.trainer.follow-ups.cta.label")}
                        </Button>
                    </Link>
                }
            >
                <HomeCardDescription label={t("component.home.trainer.follow-ups.label")}>
                    {t("component.home.trainer.follow-ups.description")}
                </HomeCardDescription>
            </HomeCard>
        </div>
    );
}
