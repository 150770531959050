import Link from "next/link";
import { useTranslation } from "react-i18next";

import { interpolate, Route } from "@sol/routing";
import { ArrowRight } from "@sol/uikit/core/icons";
import Button from "@sol/uikit/general/Button";

import { HomeCard, HomeCardDescription } from "./HomeCard";

export function TutorHome() {
    const [t] = useTranslation();

    return (
        <div className="flex h-fit items-stretch gap-4 max-lg:flex-col">
            <HomeCard
                title={t("component.home.tutor.follow-ups.title")}
                className="lg:w-1/2"
                image={<img src="/static/home/growth.svg" alt="" />}
                action={
                    <Link href={interpolate(Route.FOLLOW_UPS_LIST, {})} passHref>
                        <Button
                            size="small"
                            iconPosition="end"
                            icon={<ArrowRight variant="antd" size={16} />}
                            title={t("component.home.tutor.follow-ups.cta.title")}
                            aria-label={t("component.home.tutor.follow-ups.cta.title")}
                        >
                            {t("component.home.tutor.follow-ups.cta.label")}
                        </Button>
                    </Link>
                }
            >
                <HomeCardDescription label={t("component.home.tutor.follow-ups.label")}>
                    {t("component.home.tutor.follow-ups.description")}
                </HomeCardDescription>
            </HomeCard>

            <HomeCard
                title={t("component.home.tutor.work.title")}
                className="lg:w-1/2"
                image={<img src="/static/home/collaborate.svg" alt="" />}
                action={
                    <Link href={interpolate(Route.WORKSPACES, {})} passHref>
                        <Button
                            size="small"
                            iconPosition="end"
                            icon={<ArrowRight variant="antd" size={16} />}
                            title={t("component.home.tutor.work.cta.title")}
                            aria-label={t("component.home.tutor.work.cta.title")}
                        >
                            {t("component.home.tutor.work.cta.label")}
                        </Button>
                    </Link>
                }
            >
                <HomeCardDescription label={t("component.home.tutor.work.label")}>
                    {t("component.home.tutor.work.description")}
                </HomeCardDescription>
            </HomeCard>
        </div>
    );
}
